import request from '@/utils/request'


// 查询res-商铺列表
export function listShop(query) {
  return request({
    url: '/res/shop/list',
    method: 'get',
    params: query
  })
}

// 查询res-商铺分页
export function pageShop(query) {
  return request({
    url: '/res/shop/page',
    method: 'get',
    params: query
  })
}

// 查询res-商铺详细
export function getShop(data) {
  return request({
    url: '/res/shop/detail',
    method: 'get',
    params: data
  })
}

// 新增res-商铺
export function addShop(data) {
  return request({
    url: '/res/shop/add',
    method: 'post',
    data: data
  })
}

// 修改res-商铺
export function updateShop(data) {
  return request({
    url: '/res/shop/edit',
    method: 'post',
    data: data
  })
}

// 删除res-商铺
export function delShop(data) {
  return request({
    url: '/res/shop/delete',
    method: 'post',
    data: data
  })
}

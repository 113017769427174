<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" :destroyOnClose="true" @cancel = "onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item label="商铺编号"  prop="shopNo" >
        <a-input v-model="form.shopNo" :maxLength="16" placeholder="请输入商铺编号" />
      </a-form-model-item>
      <a-form-model-item label="名称" prop="shopName" >
        <a-input v-model="form.shopName" :maxLength="16" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="分类" prop="categoryId" >
        <a-select placeholder="请选择分类" style="width: 100%" v-model="form.categoryId" allowClear>
          <a-select-option v-for="category in categoryList" :value="category.id">
            {{category.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="可参与的用户类型" prop="userTypeList" >
        <a-checkbox-group style="width: 100%" v-model="form.userTypeList"  :options="userTypeOptions" @change="onCheckChange" >
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="简介" prop="shopInfo" >
        <a-input v-model="form.shopInfo" :maxLength="32" placeholder="请输入简介" />
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="startTime" >
        <a-date-picker style="width: 100%" v-model="form.startTime" :show-time="{formant:'HH:mm:ss'}" @change="startTimeChange" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="结束时间" prop="endTime" >
        <a-date-picker style="width: 100%" v-model="form.endTime" :show-time="{formant:'HH:mm:ss'}" @change="endTimeChange"  format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="结束模式"  prop="endType" >
        <a-radio-group v-model="form.endType">
          <a-radio v-for="(item, index) in this.customDict.EndTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.endType === 1" prop="autoEndTime"  label="自动结束时间(分钟)">
          <a-input v-model="form.autoEndTime" :maxLength="5"  @change="limitNum(form.autoEndTime)"  placeholder="(分钟)" />
      </a-form-model-item>
      <a-form-model-item label="起始价" prop="startAmount" >
        <a-input v-model="form.startAmount" :maxLength="9" @change="limitStartAmount(form.startAmount)" placeholder="请输入起始价" />
      </a-form-model-item>
      <a-form-model-item label="加价幅度" prop="addAmount" >
        <a-input v-model="form.addAmount"  :maxLength="9"   @change="limitAddAmount(form.addAmount)" placeholder="请输入加价幅度" />
      </a-form-model-item>
      <a-form-model-item label="保证金" prop="promiseAmount" >
        <a-input v-model="form.promiseAmount" :maxLength="9" @change="limitPromiseAmount(form.promiseAmount)" placeholder="请输入保证金" />
      </a-form-model-item>
      <a-form-model-item label="主图" prop="masterImg">
        <a-upload
          name="masterImg"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.masterImg"
            :src="form.masterImg"
            alt="masterImg"
            style="height: 102px; width: 102px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="详情(多图)" prop="shopImgList">
        <a-upload
          name="shopImgList"
          v-model="fileList"
          listType="picture-card"
          :fileList="fileList"
          :remove="removeFile"
          class="avatar-uploader"
          :multiple="false"
          :show-upload-list="true"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverAvatarUploadInfo">
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="是否上架" prop="isSale" >
        <a-radio-group v-model="form.isSale">
          <a-radio v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-item prop="shopDesc" label="商铺详情" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <editor v-model="form.shopDesc"  placeholder="请输入内容" />
      </a-form-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getShop, addShop, updateShop } from '@/api/res/shop'
import {mapGetters} from 'vuex'
import Editor from '@/components/Editor'
import CustomDictTag from "@/components/DictCustomTag";
import {listCategory} from "@/api/res/category";
import {generateFilePath, uploadObject} from "@/api/tool/alioss";
import {fileUpload} from "@/api/tool/common";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor,
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: '',
      submitLoading: false,
      formTitle: '',
      categoryList:null,
      fileList: [],
      userTypeOptions:[],
      // 表单参数
      form: {
        id: null,
        shopImgList: [],
        shopNo: null,
        masterImg: null,
        userTypeList: [],
        shopName: null,
        endType: null,
        autoEndTime: null,
        shopInfo: null,
        categoryId: null,

        shopDesc: null,

        shopImg: null,

        startTime: null,

        endTime: null,

        rentNum: null,

        startAmount: null,

        addAmount: null,

        promiseAmount: null,

        currentAmount: null,

        isSale: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        shopNo: [
          { required: true, message: '商铺编号不能为空', trigger: 'blur' }
        ],
        endType: [
          { required: true, message: '结束模式不能为空', trigger: 'blur' }
        ],
        userTypeList: [
          { required: true, message: '参与用户类型不能为空', trigger: 'blur' }
        ],
        startAmount: [
          { required: true, message: '起始价不能为空', trigger: 'blur' }
        ],
        addAmount: [
          { required: true, message: '加价幅度不能为空', trigger: 'blur' }
        ],
        promiseAmount: [
          { required: true, message: '保证金不能为空', trigger: 'blur' }
        ],
        shopInfo: [
          { required: true, message: '简介不能为空', trigger: 'blur' }
        ],
        shopName: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '开始时间不能为空', trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '结束时间不能为空', trigger: 'change' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
    this.getCategoryList()
    this.userTypeOptions =this.getUserTypeCheckBox()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    getUserTypeCheckBox() {
      let options = []
      this.customDict.AuthTypeEnum.forEach(e => {
        options.push({label:e.name,value:e.type})
      });
      return options;
    },
    onCheckChange() {
      console.log('改变了')
    },
    startTimeChange(date, dateString){
      this.form.startTime = dateString
    },
    endTimeChange(date, dateString){
      this.form.endTime = dateString
    },
    getCategoryList() {
      listCategory({type:20}).then(response => {
        this.categoryList = response.data
      })
    },
    limitNum(value) {
      // console.log(i);
      let val=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      this.form.autoEndTime=val;
    },
    limitStartAmount(value) {
      // console.log(i);
      let val=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.startAmount=val;
    },
    limitAddAmount(value) {
      // console.log(i);
      let val=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      this.form.addAmount=val
    },
    limitPromiseAmount(value) {
      // console.log(i);
      let val=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1');
      if(val==null||val==undefined||val==''){
        val=0
      }
      this.form.promiseAmount=val
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fileList = []
      this.form = {
        id: null,
        shopImgList: [],
        shopNo: null,
        masterImg: null,
        shopName: null,
        endType: null,
        autoEndTime: null,
        shopInfo: null,
        userType: null,
        categoryId: null,
        shopDesc: null,
        shopImg: null,
        startTime: null,
        endTime: null,
        rentNum: null,
        startAmount: null,
        addAmount: null,
        promiseAmount: null,
        currentAmount: null,
        isSale: null,
        createTime: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'shop'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      /*_this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.form.masterImg = res
          _this.$message.success('上传成功')
        })
      })*/
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.form.masterImg = res.data.fullPath
          _this.$message.success('上传成功')
        })
      })
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束

    //多图上传开始
    // 上传详情覆盖默认的上传行为
    coverAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'shop'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      const formData = new FormData()
      formData.append('file', uploadFile, url)
      _this.$nextTick(() => {
        fileUpload(formData).then(res => {
          _this.fileList.push({status: 'done', url: res.data.fullPath, uid: this.getUidRandom(), name: 'image.jpg'})
          this.form.shopImgList= []
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.shopImgList.push(e.url);
            }
          })
          console.log('结果',this.form.shopImgList)
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeFile(file) {
      var fileList = []
      this.fileList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var shopImgList = []
      this.fileList = fileList
      fileList.forEach(e => {
        shopImgList.push(e.url)
      })
      this.form.shopImgList = shopImgList
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    //多图上传结束

    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShop({"id":id}).then(response => {
        this.form = response.data
        this.fileList = []
        var infos = this.form.shopImgList ==null ? [] : this.form.shopImgList;
        for (var j = 0; j < infos.length; j++) {
          this.fileList.push({status: 'done', url: infos[j], uid: this.getUidRandom(), name: 'image.jpg'})
        }

        this.open = true
        this.formTitle = '修改'
      })
    },
    handleCopy(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShop({"id":id}).then(response => {
        this.form = response.data
        this.form.id = null
        this.fileList = []
        var infos = this.form.shopImgList ==null ? [] : this.form.shopImgList;
        for (var j = 0; j < infos.length; j++) {
          this.fileList.push({status: 'done', url: infos[j], uid: this.getUidRandom(), name: 'image.jpg'})
        }
        this.open = true
        this.formTitle = '复制'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShop(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addShop(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
